html,
body {
  height: 100%;
  width: 100%;
  min-width: 375px;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  margin: 0;
  background: #d3d3d3;
}

.ant-layout-header {
  background: none;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0;
  z-index: 900;
  min-height: 30px;
  height: auto;
  line-height: 20px !important;
}

.ant-layout {
  background: #fff;
  min-height: 100vh;
}

.ant-layout-content {
  min-height: calc(100vh - 170px);
}

.ant-menu-submenu-title {
  width: 400px;
}

.ant-menu-sub {
  z-index: 1000;
}
.ant-dropdown {
  z-index: 12000;
  background: #fff;
  min-height: 40px;
  height: auto;
}
.ant-menu-submenu-arrow {
}
.ant-popover-content .ant-checkbox-group .ant-checkbox-group-item {
  width: 200px;
}
.ant-popover-content .ant-radio-wrapper {
  width: 200px;
}

.headNavi {
  margin-left: auto;
  display: flex;
}

.headNaviMenu {
  border: 1px solid #67a2b8;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 2px 4px 0 4px;
  font-size: 13px;
}
.ant-dropdown-link {
  color: #67a2b8;
}

.aLink {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #0c6191;
}

.headerMenuBox {
  position: fixed;
  z-index: 10000;
  top: 50px;
  width: 300px;
  height: calc(100vh - 50px);
  box-shadow: -8px 10px 10px 2px rgba(150, 150, 150, 0.17);
  border-left: 1px solid #ddd;
  background: #fff;
  overflow-y: auto;
}

input[type='password'] {
  background-color: #f0f0f0;
}

a.ant-btn {
  padding: 2px 10px !important;
}

.image-gallery-bullets .image-gallery-bullets-container {
  text-align: center;
}
.image-gallery-bullets {
  bottom: auto;
  top: 2px;
  text-align: center;
  height: 2px;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: none;
  border: none;
  border-radius: 10px;
  height: 6px;
  width: 6px;
  padding: 0;
  background: #c4cbd0;
  transform: none;
  margin: 0 3px 2px 3px;
}
.image-gallery-bullets .image-gallery-bullet.active {
  border: none;
  background: #1890ff;
  transform: none;
}
@media screen and (max-width: 600px) {
  .image-gallery-bullets .image-gallery-bullets-container {
    text-align: center;
  }
  .image-gallery-bullets {
    bottom: -6px;
    top: auto;
  }
  .image-gallery-bullets .image-gallery-bullet {
    height: 6px;
    width: 6px;
  }
}
.tui-image-editor-header-logo {
  display: none;
}
.ant-modal-body .tui-image-editor-container.top .tui-image-editor-controls-logo {
  display: none;
}
.tui-image-editor-header-buttons {
  float: none;
  display: none;
}
.ant-modal-body .tui-image-editor-container.top .tui-image-editor-controls-buttons {
  float: none;
  display: none;
}

.addUploader .ant-upload.ant-upload-select-picture-card {
  height: 40px;
  width: 100%;
}

.toolbarClassName {
  background: #eeeeee !important;
  margin-bottom: 0 !important;
  border-radius: 6px 6px 0 0 !important;
  border: 1px solid #ddd !important;
  border-bottom: none !important;
  padding: 4px 0 4px 4px !important;
}
